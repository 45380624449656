<template>
  <div class="height-100 max_box">
    <!-- <div class="audio-box">
      <audio src="audio/bg.mp3" ref="bgAudio"></audio>
      <audio :src="audioSrcList[0]" ref="audio1"></audio>
      <audio :src="audioSrcList[1]" ref="audio2"></audio>
      <audio :src="audioSrcList[2]" ref="audio3"></audio>
      <audio :src="audioSrcList[3]" ref="audio4"></audio>
      <audio :src="audioSrcList[4]" ref="audio5"></audio>
      <audio :src="audioSrcList[5]" ref="audio6"></audio>
      <audio :src="audioSrcList[6]" ref="audio7"></audio>
      <audio :src="audioSrcList[7]" ref="audio8"></audio>
      <audio :src="audioSrcList[8]" ref="audio9"></audio>
    </div> -->
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="wrap">
        <p class="stock-name">{{ stockName }}</p>
        <p class="main-title">个股诊断</p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>综合评分</span></p>
        <div class="chart1" id="chart1" ref=""></div>
        <div class="intro">
          <p>综合评分</p>
          <p>{{ card2Info.fVal }}</p>
          <p class="sScoreDesc_w">{{ card2Info.sScoreDesc }}</p>
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>上涨潜力</span></p>
        <h2 style="margin-bottom: 10px">股价模拟走势</h2>
        <p class="Desc" style="margin-bottom: 10px">{{ sDesc }}</p>
        <div class="box-content">
          <div class="chart3" id="chart3_1" ref="chart3_1"></div>
          <div class="chart3" id="chart3_2" ref="chart3_2"></div>
        </div>
        <h2>筹码分布</h2>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>压力位</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>平均成本</span>
            </p>
            <p class="line_support">
              <span></span>
              <span>支撑位</span>
            </p>
          </div>
        </div>
        <div class="chart_content">
          <div class="d3_chart d3_chip" id="card5_chart"></div>
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>市场热度</span></p>
        <h2>人气指数</h2>
        <p class="Desc">{{ sPopularityDesc }}</p>
        <div class="chart5" id="chart5" ref="chart5"></div>
        <h2>机构调研</h2>
        <p class="Desc">
          最近三个月，共{{ fVal }}家机构对该股进行调研，行业排名{{
            iIndustryRank
          }}/{{ iIndDtNum }}
        </p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>机构调研</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>
        <div class="chart5" id="chart5_2" ref="chart5_2"></div>
        <h2>机构评级</h2>
        <p class="Desc" style="margin-bottom: 10px">
          {{ SecPortraitDec }}
        </p>
        <div class="grade_box">
          <div class="grade">
            <span></span><span></span><span></span><span></span><span></span>
          </div>
        </div>

        <div style="width：100%">
          <span class="fl" style="font-size: 16px">卖出</span>
          <span class="fr" style="font-size: 16px">买入</span>
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>主力强度</span></p>
        <h2>主力资金<span class="sub_title">（亿元）</span></h2>
        <p class="Desc">
          {{ sMainCapDesc }}
        </p>
        <p></p>
        <div class="chart5" id="chart6" ref="chart6"></div>
        <h2>主力成本<span class="sub_title"> (元)</span></h2>
        <p class="Desc">
          目前主力成本{{ fMainAvgCost1 }}元，{{ sMainChgDesc }}
        </p>
        <div class="chart5" id="chart7" ref="chart7"></div>
        <h2>龙虎榜 <span class="sub_title">(万元)</span></h2>
        <p class="Desc">
          {{ sLongHuDesc }}
        </p>
        <div
          v-show="fNetBuy.length > 0"
          class="chart5"
          id="chart8"
          ref="chart8"
        ></div>
        <div
          style="
            text-align: center;
            color: #cecece;
            border: 1px solid #707070;
            height: 100px;
            line-height: 100px;
          "
          v-if="fNetBuy.length === 0"
        >
          暂无数据
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>走势分析</span></p>
        <h2>市场表现</h2>
        <p class="Desc">
          近一个月个股走势{{ descObj.desc1 }}大盘走势，{{
            descObj.desc2
          }}行业走势
        </p>
        <div class="product">
          <div class="product_line">
            <div class="line1">
              <span v-if="sName3"></span>
              <span class="Desc">{{ sName3 }}</span>
            </div>
            <div class="line2">
              <span></span>
              <span class="Desc">{{ sName2 }}</span>
            </div>
            <div class="line3">
              <span></span>
              <span class="Desc">{{ sName1 }}</span>
            </div>
          </div>
        </div>
        <div
          class="chart5"
          id="chart9"
          ref="chart9"
          style="margin-bottom: 94px"
        ></div>
        <h2>板块潜力</h2>
        <p class="Desc">近一个月板块走势{{ descObj.desc2 }}大盘走势</p>
        <div class="product">
          <div class="product_line">
            <div class="line2">
              <span></span>
              <span class="Desc">{{ stPlateUpdownsName }}</span>
            </div>
            <div class="line3">
              <span></span>
              <span class="Desc">{{ stIndexUpdownsName }}</span>
            </div>
          </div>
        </div>
        <div class="chart5" id="chart10" ref="chart10"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>价值评估</span></p>
        <h2>估值</h2>
        <p class="Desc">{{ sFinanceDesc }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>市盈率</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>
        <div
          class="chart11"
          id="chart11"
          ref="chart11"
          style="margin-bottom: 44px"
        ></div>
        <p class="Desc">{{ sFinanceDesc2 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>市净率</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>
        <div class="chart11" id="chart12" ref="chart12"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>价值评估</span></p>
        <h2>成长</h2>
        <p class="Desc">{{ sFinanceDesc3 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>营收（亿元）</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>
        <div
          class="chart11"
          id="chart13"
          ref="chart13"
          style="margin-bottom: 44px"
        ></div>
        <p class="Desc">{{ sFinanceDesc4 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>净利润（亿元）</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>

        <div class="chart11" id="chart14" ref="chart14"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card9"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>价值评估</span></p>
        <h2>盈利</h2>
        <p class="Desc">{{ sFinanceDesc5 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>净资产收益率（%）</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>
        <div
          class="chart11"
          id="chart15"
          ref="chart15"
          style="margin-bottom: 44px"
        ></div>
        <p class="Desc">{{ sFinanceDesc6 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>每股收益（元）</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>

        <div class="chart11" id="chart16" ref="chart16"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card10"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>价值评估</span></p>
        <h2>资产</h2>
        <p class="Desc">{{ sFinanceDesc7 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>总资产（亿元）</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>
        <div
          class="chart11"
          id="chart17"
          ref="chart17"
          style="margin-bottom: 44px"
        ></div>
        <p class="Desc">{{ sFinanceDesc8 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>资产负债率（%）</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>

        <div class="chart11" id="chart18" ref="chart18"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card11"
    >
      <div class="container">
        <p class="head-tit">个股诊断<span>价值评估</span></p>
        <h2>现金</h2>
        <p class="Desc">{{ sFinanceDesc9 }}</p>
        <div class="progess">
          <div class="line_leged">
            <p class="line_press">
              <span></span>
              <span>经营活动现金净额（亿元）</span>
            </p>
            <p class="line_avge">
              <span></span>
              <span>行业平均值</span>
            </p>
          </div>
        </div>
        <div class="chart11" id="chart19" ref="chart19"></div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card12"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/geguend.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>

    <div class="bg_gif"></div>
  </div>
</template>

<script>
import $ from "jquery";
import { chipChart } from "../../utils/chipChart";
import { dataProcess } from "../../utils/dataProcess";
import { GetParams, GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
import CanvasKeyFrames from "canvaskeyframes";
export default {
  name: "Template14",
  data() {
    return {
      stockName: "",
      chartData1: [],
      card2Info: {
        // fVal: 0,
        // sScoreDesc: "",
      },
      chartData5: [],
      chartData5_time: [],
      chartData5Info: "",
      chart5_d3: [],
      audioSrcList: [],
      audioTimeList: [],
      audioData: [],
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },

      recordtimeAll: null,
      sDesc: "",
      vtClose: [],
      data3_2: [],
      sPopularityDesc: "",
      fIndInsResAvg: "",
      fVal: "",
      max_chartData5: "",
      min_chartData5: "",
      iIndustryRank: "",
      iIndDtNum: "",
      SecPortraitDec: "",
      sMainCapDesc: "",
      sLongHuDesc: "",
      sMainChgDesc: "",
      fMainAvgCost1: "",
      chart6Data: [],
      chart6Time: [],
      fMainAvgCost: [],
      sUpdateDate: [],
      sDate: [],
      fNetBuy: [],
      sName1: "",
      sName2: "",
      sName3: "",
      stPlateUpdownsName: "",
      stIndexUpdownsName: "",
      descObj: {},
      Chart9_data1: [],
      Chart9_data2: [],
      Chart9_data3: [],
      TradeDate: [],
      sFinanceDesc: "",
      chart11_date: [],
      chart11_data1: [],
      chart11_data2: [],
      data3_2_date: [],
      chart12_data1: [],
      chart12_data2: [],
      chart12_date: [],
      sFinanceDesc2: "",
      sFinanceDesc3: "",
      sFinanceDesc4: "",
      sFinanceDesc5: "",
      sFinanceDesc6: "",
      sFinanceDesc7: "",
      sFinanceDesc8: "",
      sFinanceDesc9: "",
      chart13_data1: [],
      chart13_data2: [],
      chart13_date: [],
      chart14_data1: [],
      chart14_data2: [],
      chart14_date: [],
      chart15_data1: [],
      chart15_data2: [],
      chart15_date: [],
      chart16_data1: [],
      chart16_data2: [],
      chart16_date: [],
      chart17_data1: [],
      chart17_data2: [],
      chart17_date: [],
      chart18_data1: [],
      chart18_data2: [],
      chart18_date: [],
      chart19_data1: [],
      chart19_data2: [],
      chart19_date: [],
      max_data3_2: "",
      min_data3_2: "",
      max_vtClose: "",
      min_vtClose: "",
      Chart9_date: "",
      min: "",
      max: "",
      videoTotalTime: null,
      // device_id:null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { stockName, audioSrcList, chartData1 } = this;
      return {
        stockName,
        audioSrcList,
        chartData1,
      };
    },

    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  watch: {
    allReady(val) {
      console.log(val);
      if (
        val.stockName.length > 0 &&
        val.audioSrcList.length > 0 &&
        val.chartData1.length > 0
      ) {
        if (this.ip && this.port) {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordstartPc(this.newIpPort, params)
            .then(({ code }) => {})
            .catch((err) => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
          this.load();
        } else {
          // alert("没获取到ip,port");
          this.load();
        }
      }
    },
  },
  methods: {
    getData() {
      let params = {
        model_id: 14,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 14,
          url_extra_params: this.url_extra_params,
        };
      }

      this.stockName = "平安银行";

      ApiServe.getDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.getScoreDetai(
              data.chart_data_1[0].url,
              data.chart_data_1[0].payload
            );
            this.getTrendPredictDetail(
              data.chart_data_2[0].url,
              data.chart_data_2[0].payload
            );
            this.getSecKline(data.chart_data_2[1].url);
            this.getCmfbwz(
              data.chart_data_3[0].url,
              data.chart_data_3[0].payload
            );
            this.getPopularityDetail(
              data.chart_data_4[0].url,
              data.chart_data_4[0].payload
            );
            this.getInsResDetail(
              data.chart_data_5[0].url,
              data.chart_data_5[0].payload
            );
            this.getSecPortrait(data.extra_param_6.url);

            this.getCapitalDDZ(data.chart_data_6[0].url);

            this.getMarketTrend(data.chart_data_9[0].url);
            this.GetConsultStock1(
              data.extra_param_7.url,
              data.extra_param_7.payload
            );
            this.getChipDist(
              data.extra_param_8.url,
              data.extra_param_8.payload
            );
            this.GetConsultStock(
              data.extra_param_9.url,
              data.extra_param_9.payload
            );
            this.getHisChipDistSimple(
              data.chart_data_7[0].url,
              data.chart_data_7[0].payload
            );
            this.getLongHuDetail(
              data.chart_data_8[0].url,
              data.chart_data_8[0].payload
            );

            this.getMarkInfo(data.chart_data_10[0].url);

            this.getFinanceDetail1(
              data.chart_data_11[0].url,
              data.chart_data_11[0].payload
            );
            this.getFinanceDetail2(
              data.chart_data_12[0].url,
              data.chart_data_12[0].payload
            );
            this.getFinanceDetail3(
              data.chart_data_13[0].url,
              data.chart_data_13[0].payload
            );
            this.getFinanceDetail4(
              data.chart_data_14[0].url,
              data.chart_data_14[0].payload
            );
            this.getFinanceDetail5(
              data.chart_data_15[0].url,
              data.chart_data_15[0].payload
            );
            this.getFinanceDetail6(
              data.chart_data_16[0].url,
              data.chart_data_16[0].payload
            );
            this.getFinanceDetail7(
              data.chart_data_17[0].url,
              data.chart_data_17[0].payload
            );
            this.getFinanceDetail8(
              data.chart_data_18[0].url,
              data.chart_data_18[0].payload
            );
            this.getFinanceDetail9(
              data.chart_data_19[0].url,
              data.chart_data_19[0].payload
            );
            if (this.stockName.length == 0 && this.ip) {
              ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    // 获取旁白
    getAudio() {
      let params = {
        model_id: 14,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 14,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.audioData = data || [];
            this.audioSrcList = data.map((item) => {
              return item.audio_link;
            });
            this.audioTimeList = data.map((item) => {
              return item.scene_duration;
            });
            this.recordtimeAll =
              this.audioTimeList.reduce(function (prev, curr, idx, arr) {
                return prev + curr;
              }, 0) +
              this.audioTimeList.length +
              3 +
              5 +
              3;

            if (this.audioSrcList.length == 0 && this.ip) {
              ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    //时间戳转换
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      return Y + M + D;
    },

    //获取综合评分
    async getScoreDetai(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtConsultScore[0];
        this.card2Info = dataObj;
        this.chartData1 = [
          dataObj.stRiseNewScoreDesc.iScore,
          dataObj.stConsultScoreDesc.iScore,
          dataObj.stValueScoreDesc.iScore,
          dataObj.stTrendScoreDesc.iScore,
          dataObj.stMainScoreDesc.iScore,
          dataObj.stMktHotScoreDesc.iScore,
        ];
      }
    },
    //获取股价模拟走势1
    async getTrendPredictDetail(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtTrendPredictNew[0];
        // console.log("1:", dataObj);
        this.sDesc = dataObj.sDesc;
        this.vtClose = dataObj.vtClose;
        this.max_vtClose = Math.max.apply(null, this.vtClose);
        this.min_vtClose = Math.min.apply(null, this.vtClose);
        // console.log(this.max_vtClose, this.min_vtClose);
      }
    },
    //近期走势2
    async getSecKline(url) {
      let { content, ret } = await this.$axios.get(url, null, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      // console.log(JSON.parse(content));
      if (ret == 0) {
        let dataObj =
          JSON.parse(content).stSimilarKLineValue.stSourceSimilarKLineRecord
            .vSimilarKLineDesc;
        // console.log(dataObj);

        this.data3_2_date = dataObj.map((item) => {
          return item.lYmd;
        });
        // console.log(this.data3_2_date.slice(9));
        this.data3_2 = dataObj.map((item) => {
          return item.fClose;
        });
        this.max_data3_2 = Math.max.apply(null, this.data3_2);
        this.min_data3_2 = Math.min.apply(null, this.data3_2);

        // console.log(this.data3_2, this.data3_2_date);
        // console.log(this.max_data3_2, this.min_data3_2);
        var Data = this.data3_2.concat(this.vtClose);
        console.log(Data);
        this.max = Math.max.apply(null, Data);
        this.min = Math.min.apply(null, Data);
        console.log(this.max, this.min);
        // this.loadChart3_1();
        // this.loadChart3_2();
      }
    },

    //获取人气指数
    async getPopularityDetail(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtPopularity[0];
        // console.log("人气指数");
        // console.log(dataObj);
        this.sPopularityDesc = dataObj.sPopularityDesc;
        let arrData = [];
        let arrTime = [];
        for (let i = 0; i < dataObj.vtPopuIndex.length; i++) {
          const element = dataObj.vtPopuIndex[i];
          arrTime.push(element.sDate);
          arrData.push(element.iIndexVal);
        }
        this.chartData5 = arrData;
        this.max_chartData5 = Math.max.apply(null, this.chartData5);
        this.min_chartData5 = Math.min.apply(null, this.chartData5);
        this.chartData5_time = arrTime;
        // console.log(this.chartData5_time);
        // this.loadChart5();
      }
    },
    //获取机构调研
    async getInsResDetail(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtInsRes;
        // console.log(dataObj);
        this.fIndInsResAvg = dataObj[0].fIndInsResAvg;
        this.fVal = dataObj[0].fVal;
        this.iIndustryRank = dataObj[0].iIndustryRank;
        this.iIndDtNum = dataObj[0].iIndDtNum;
        // console.log(this.fIndInsResAvg, this.fVal);
      }
      // this.loadChart5_2();
    },
    //获取机构评级描述
    async getSecPortrait(url) {
      let { content, ret } = await this.$axios.get(url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (ret == 0) {
        let dataObj = JSON.parse(content).sDesc;
        // console.log(dataObj);
        this.SecPortraitDec = dataObj;
      }
    },
    //获取主力资金
    async getCapitalDDZ(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vCapitalDDZDesc;
        // console.log(dataObj);
        this.chart6Data = dataObj.map((item) => {
          return (
            (item.fBigInAmt -
              item.fBigOutAmt +
              item.fSuperInAmt -
              item.fSuperOutAmt) /
            100000000
          ).toFixed(2);
        });
        this.chart6Time = dataObj.map((item) => {
          return this.timestampToTime(item.lTime);
        });
        // console.log(this.chart6Time);
      }
      // this.loadChart6();
    },
    //获取主力资金的描述
    async GetConsultStock1(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtMainCap[0];
        // console.log(dataObj);
        this.sMainCapDesc = dataObj.sMainCapDesc;
        console.log(this.sMainCapDesc);
      }
    },
    //获取主力成本的描述
    async getChipDist(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content);
        // console.log(dataObj);
        this.sMainChgDesc = dataObj.sMainChgDesc;
        this.fMainAvgCost1 = dataObj.vtChipDistRsp[1].fMainAvgCost;
      }
    },
    //获取龙虎榜的描述
    async GetConsultStock(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtLongHu[0];
        // console.log(dataObj);
        this.sLongHuDesc = dataObj.sLongHuDesc;
      }
    },
    //获取主力成本
    async getHisChipDistSimple(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtChipDistRsp;
        // console.log(dataObj);
        this.sUpdateDate = dataObj.map((item) => {
          return item.sUpdateDate;
        });
        this.fMainAvgCost = dataObj.map((item) => {
          return item.fMainAvgCost;
        });
        if (this.fMainAvgCost[0] == 0) {
          this.fMainAvgCost = this.fMainAvgCost.slice(1);
        } else {
          this.fMainAvgCost = this.fMainAvgCost;
        }
        this.max_fMainAvgCost = Math.max.apply(null, this.fMainAvgCost);
        this.min_fMainAvgCost = Math.min.apply(null, this.fMainAvgCost);
        // console.log(this.sUpdateDate, this.fMainAvgCost);
      }
      // this.loadChart7();
    },
    //获取龙虎榜
    async getLongHuDetail(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtLongHu[0].vtDateNet;
        // console.log(dataObj);
        this.sDate = dataObj.map((item) => {
          return item.sDate;
        });
        this.fNetBuy = dataObj.map((item) => {
          return item.fNetBuy;
        });
      }
      // this.loadChart8();
    },

    setMarketDesc(comparewithIndex, compareWithPlate) {
      var desc1, desc2;
      if (comparewithIndex) {
        desc1 = "优于";
      } else {
        desc1 = "弱于";
      }
      if (compareWithPlate) {
        desc2 = "优于";
      } else {
        desc2 = "弱于";
      }
      return {
        desc1: desc1,
        desc2: desc2,
      };
    },
    handleSectionData(marketTrendData) {
      const data = {
        sDesc: "",
        stIndexUpdowns: {
          vtUpdowns: [],
          sName: marketTrendData.stIndexUpdowns.sName,
        },
        stPlateUpdowns: {
          vtUpdowns: [],
          sName: marketTrendData.stPlateUpdowns.sName,
        },
        stStockUpdowns: {
          vtUpdowns: [],
          sName: marketTrendData.stStockUpdowns.sName,
        },
        vPlateUpdowns: new Array(marketTrendData.vPlateUpdowns.length),
      };
      const { length } = marketTrendData.stIndexUpdowns.vtUpdowns;
      const endStamp =
        marketTrendData.stIndexUpdowns.vtUpdowns[length - 1].iTradeDate; // 得到最新的时间戳
      const count = 0;
      const startStamp = endStamp - 60 * 60 * 24 * 31;
      for (
        var i = 0;
        i < marketTrendData.stIndexUpdowns.vtUpdowns.length;
        i++
      ) {
        if (
          marketTrendData.stIndexUpdowns.vtUpdowns[i].iTradeDate >= startStamp
        ) {
          data.stIndexUpdowns.vtUpdowns.push(
            marketTrendData.stIndexUpdowns.vtUpdowns[i]
          );
        }
      }

      data.stIndexUpdowns.basePercent =
        data.stIndexUpdowns.vtUpdowns[0].sUpDowns;

      for (var i = 0; i < data.stIndexUpdowns.vtUpdowns.length; i++) {
        data.stIndexUpdowns.vtUpdowns[i].sUpDowns =
          (1 + parseFloat(data.stIndexUpdowns.vtUpdowns[i].sUpDowns, 10)) /
            (1 + parseFloat(data.stIndexUpdowns.basePercent, 10)) -
          1;
      }

      for (
        var i = 0;
        i < marketTrendData.stPlateUpdowns.vtUpdowns.length;
        i++
      ) {
        if (
          marketTrendData.stPlateUpdowns.vtUpdowns[i].iTradeDate >= startStamp
        ) {
          data.stPlateUpdowns.vtUpdowns.push(
            marketTrendData.stPlateUpdowns.vtUpdowns[i]
          );
        }
      }

      if (data.stPlateUpdowns.vtUpdowns.length != 0) {
        data.stPlateUpdowns.basePercent =
          data.stPlateUpdowns.vtUpdowns[0].sUpDowns;
        for (var i = 0; i < data.stPlateUpdowns.vtUpdowns.length; i++) {
          data.stPlateUpdowns.vtUpdowns[i].sUpDowns =
            (1 + parseFloat(data.stPlateUpdowns.vtUpdowns[i].sUpDowns, 10)) /
              (1 + parseFloat(data.stPlateUpdowns.basePercent, 10)) -
            1;
        }
      }

      for (
        var i = 0;
        i < marketTrendData.stStockUpdowns.vtUpdowns.length;
        i++
      ) {
        if (
          marketTrendData.stStockUpdowns.vtUpdowns[i].iTradeDate >= startStamp
        ) {
          marketTrendData.stStockUpdowns.vtUpdowns[i].date =
            this.timestampToTime(
              marketTrendData.stStockUpdowns.vtUpdowns[i].iTradeDate
            );
          data.stStockUpdowns.vtUpdowns.push(
            marketTrendData.stStockUpdowns.vtUpdowns[i]
          );
        }
      }

      data.stStockUpdowns.basePercent =
        data.stStockUpdowns.vtUpdowns[0].sUpDowns;
      for (var i = 0; i < data.stStockUpdowns.vtUpdowns.length; i++) {
        data.stStockUpdowns.vtUpdowns[i].sUpDowns =
          (1 + parseFloat(data.stStockUpdowns.vtUpdowns[i].sUpDowns, 10)) /
            (1 + parseFloat(data.stStockUpdowns.basePercent, 10)) -
          1;
      }
      for (var i = 0; i < marketTrendData.vPlateUpdowns.length; i++) {
        data.vPlateUpdowns[i] = {
          vtUpdowns: [],
          sName: marketTrendData.vPlateUpdowns[i].sName,
        };
        for (
          var j = 0;
          j < marketTrendData.vPlateUpdowns[i].vtUpdowns.length;
          j++
        ) {
          if (
            marketTrendData.vPlateUpdowns[i].vtUpdowns[j].iTradeDate >=
            startStamp
          ) {
            data.vPlateUpdowns[i].vtUpdowns.push(
              marketTrendData.vPlateUpdowns[i].vtUpdowns[j]
            );
          }
        }
        data.vPlateUpdowns[i].basePercent =
          data.vPlateUpdowns[i].vtUpdowns[0].sUpDowns;
      }

      for (var i = 0; i < marketTrendData.vPlateUpdowns.length; i++) {
        for (var j = 0; j < data.vPlateUpdowns[i].vtUpdowns.length; j++) {
          data.vPlateUpdowns[i].vtUpdowns[j].sUpDowns =
            (1 + parseFloat(data.vPlateUpdowns[i].vtUpdowns[j].sUpDowns, 10)) /
              (1 + parseFloat(data.vPlateUpdowns[i].basePercent, 10)) -
            1;
        }
      }
      return data;
    },

    //市场表现
    async getMarketTrend(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        let dataObj = JSON.parse(content);
        // console.log(dataObj);
        this.sName1 = dataObj.stIndexUpdowns.sName;
        this.sName2 = dataObj.stPlateUpdowns.sName;
        this.sName3 = dataObj.stStockUpdowns.sName;

        const data = this.handleSectionData(dataObj);
        // console.log(data);
        var stockUpDowns = data.stStockUpdowns.vtUpdowns,
          IndexUpdowns = data.stIndexUpdowns.vtUpdowns,
          PlateUpdowns = data.stPlateUpdowns.vtUpdowns;

        this.Chart9_data2 = IndexUpdowns.map((item) => {
          return item.sUpDowns;
        });
        this.Chart9_data1 = stockUpDowns.map((item) => {
          return item.sUpDowns;
        });
        this.Chart9_data3 = PlateUpdowns.map((item) => {
          return item.sUpDowns;
        });
        this.Chart9_date = stockUpDowns.map((item) => {
          return this.timestampToTime(item.iTradeDate);
        });

        //设置市场表现描述
        var stockTotal = 0,
          plateTotal = 0,
          indexTotal = 0;
        for (var i = 0; i < data.stStockUpdowns.vtUpdowns.length; i++) {
          indexTotal += data.stIndexUpdowns.vtUpdowns[i].sUpdowns / 1;
          if (data.stPlateUpdowns.vtUpdowns[i] != undefined) {
            plateTotal += data.stPlateUpdowns.vtUpdowns[i].sUpdowns / 1;
          }
          stockTotal += data.stStockUpdowns.vtUpdowns[i].sUpdowns / 1;
        }
        var compare1 = stockTotal > indexTotal ? true : false;
        var compare2 = stockTotal > plateTotal ? true : false;
        this.descObj = this.setMarketDesc(compare1, compare2);
      }
      // this.loadChart9();
      // this.loadChart10();
    },

    //板块潜力
    async getMarkInfo(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        let dataObj = JSON.parse(content);
        // console.log(dataObj);
        this.stPlateUpdownsName = dataObj.stPlateUpdowns.sName;
        this.stIndexUpdownsName = dataObj.stIndexUpdowns.sName;
      }
    },
    //eFinType 市盈率8，市净率9， 营收1，净利润2，净资产收益率3，每股收益4，总资产5，资产负债率6，现金7，
    async getFinanceDetail1(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc = dataObj.sFinanceDesc;
        this.chart11_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart11_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart11_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
        // this.loadChart11();
        // this.loadChart12();
      }
    },
    async getFinanceDetail2(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc2 = dataObj.sFinanceDesc;
        this.chart12_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart12_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart12_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },
    async getFinanceDetail3(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc3 = dataObj.sFinanceDesc;
        this.chart13_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart13_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart13_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },
    async getFinanceDetail4(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc4 = dataObj.sFinanceDesc;
        this.chart14_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart14_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart14_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },
    async getFinanceDetail5(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc5 = dataObj.sFinanceDesc;
        this.chart15_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart15_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart15_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },
    async getFinanceDetail6(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc6 = dataObj.sFinanceDesc;
        this.chart16_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart16_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart16_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },
    async getFinanceDetail7(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc7 = dataObj.sFinanceDesc;
        this.chart17_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart17_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart17_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },
    async getFinanceDetail8(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc8 = dataObj.sFinanceDesc;
        this.chart18_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart18_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart18_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },
    async getFinanceDetail9(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtFinDateRst[0].stFinDate;
        // console.log(dataObj);
        this.sFinanceDesc9 = dataObj.sFinanceDesc;
        this.chart19_date = dataObj.vtDateValue.map((item) => {
          return item.sYearMonth;
        });
        this.chart19_data1 = dataObj.vtDateValue.map((item) => {
          return item.fValue;
        });
        this.chart19_data2 = dataObj.vtDateValueAvg.map((item) => {
          return item.fValue;
        });
      }
    },

    // 获取筹码分布数据
    async getCmfbwz(url, params) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl, params);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtChipDistRsp;
        this.dataList = dataObj;
        // this.extra_param_7 = dataObj[0]; //筹码分布文字
        //获得所有帧里的最大值和最小值
        var minMax = dataProcess.getMinMax(dataObj, "vStockChipDist");
        //数据格式转换供图表用
        for (var i = 0; i < dataObj.length; i++) {
          dataObj[i].dataConverted = dataProcess.convertData({
            data: dataObj[i].vStockChipDist,
            name: "fPrice",
            value: "fPer",
            avePrice: dataObj[i].fAvgCost,
            closePrice: dataObj[i].fClosePrice,
            pressPrice: dataObj[i].fPressPrice,
            supportPrice: dataObj[i].fSupportPrice,
            xMax: minMax.xMax,
            yMax: minMax.yMax,
            yMin: minMax.yMin,
          });
        }
        this.chart5_d3 = JSON.parse(JSON.stringify(dataObj)).reverse();
      }
    },

    loadChart1() {
      let myChart = this.$echarts.init(
        document.getElementById("chart1"),
        null,
        { renderer: "svg" }
      );
      let option = {
        radar: {
          name: {
            textStyle: {
              color: "#fff",
              fontSize: 30,
            },
          },
          indicator: [
            { name: "上涨", max: 100 },
            { name: "牛人", max: 100 },
            { name: "价值", max: 100 },
            { name: "走势", max: 100 },
            { name: "主力", max: 100 },
            { name: "市场", max: 100 },
          ],
          axisTick: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
          splitArea: {
            areaStyle: {
              color: ["#F68630"],
            },
          },
          axisLine: {
            lineStyle: {
              color: "#070A30",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#070A30",
            },
          },
          splitNumber: 4,
        },
        series: [
          {
            name: "综合评分",
            type: "radar",
            // areaStyle: {normal: {}},
            data: [
              {
                value: this.chartData1,
                name: "综合评分",
                symbol: "none",
                lineStyle: {
                  color: "#64CC5D",
                },
                areaStyle: {
                  opacity: 0.77,
                  color: "#64CC5D",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    //股票模拟走势1
    loadChart3_1() {
      let myChart = this.$echarts.init(
        document.getElementById("chart3_1"),
        null,
        { renderer: "svg" }
      );
      // 绘制图表
      myChart.setOption({
        title: {
          text: "近期走势",
          textStyle: {
            fontSize: 18,
            color: "#C4C4C4",
          },
        },
        grid: {
          left: "0px",
          top: "30px",
          bottom: "30px",
          right: "0px",
        },
        xAxis: {
          type: "category",
          data: this.data3_2_date,
          axisTick: { show: false },
          axisLabel: {
            fontSize: 16,
            align: "left",
            interval: 15,
            padding: [0, 10, 0, 0],
          },
        },
        yAxis: [
          {
            type: "value",
            max: this.max,
            min: this.min,
            position: "left",
            axisLine: { show: true },
            axisLable: { show: false },
            splitLine: {
              lineStyle: {
                color: "#3D3D3D",
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            position: "right",
            axisLine: { show: true, lineStyle: { type: "dashed" } },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            data: this.data3_2,
            type: "line",
            symbol: "none",
            areaStyle: {},
          },
        ],
      });
    },
    //股票模拟走势2
    loadChart3_2() {
      let myChart = this.$echarts.init(
        document.getElementById("chart3_2"),
        null,
        { renderer: "svg" }
      );
      // 绘制图表
      myChart.setOption({
        title: {
          text: "模拟走势",
          textStyle: {
            fontSize: 18,
            color: "#C4C4C4",
          },
        },
        grid: {
          left: "0px",
          top: "30px",
          bottom: "30px",
          right: "0px",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          max: this.max,
          min: this.min,
          axisLine: { show: true },
          axisLable: { show: false },
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          position: "right",
          axisTick: { show: false },
        },
        series: [
          {
            data: this.vtClose,
            type: "line",
            lineStyle: {
              color: "#64CC5D",
            },
            itemStyle: {
              color: "#64CC5D",
            },
          },
        ],
      });
    },

    // 筹码分布
    loadChart5D3(currentPlayingFrame = 0) {
      // console.log(this.chart5_d3);
      const totalPlayingFrame = this.chart5_d3.length;
      setTimeout(() => {
        if (currentPlayingFrame < totalPlayingFrame) {
          currentPlayingFrame++;
          chipChart.drawChip({
            id: "card5_chart",
            data: this.chart5_d3[currentPlayingFrame - 1].dataConverted,
            width: document.documentElement.clientWidth - 30,
            ratio: 0.6,
          });
          this.extra_param_7 = this.chart5_d3[currentPlayingFrame - 1]; //渲染图上面的那段话的数字
          let progessNum =
            (this.extra_param_7.fEarningPer * 100).toFixed(2) + "%";
          $(".progess_i").css("width", progessNum);
          this.loadChart5D3(currentPlayingFrame); //渲染图
        }
      }, 300);
    },
    loadChart5() {
      let myChart = this.$echarts.init(
        document.getElementById("chart5"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        xAxis: {
          type: "category",
          data: this.chartData5_time,

          axisLine: {
            lineStyle: {
              color: "#AEAEAE",
            },
          },
          axisLabel: {
            fontSize: 15,
            align: "left",
            interval: 3,
            padding: [0, 0, 0, 30],
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 10,
          left: 5,
          right: 50,
        },
        yAxis: {
          type: "value",
          min: this.min_chartData5,
          max: this.max_chartData5,
          axisLabel: {
            color: "#AEAEAE",
          },
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#AEAEAE",
            },
          },
          axisLabel: {
            fontSize: 15,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            data: this.chartData5,
            type: "line",
            symbol: "none",
            areaStyle: {
              opacity: 0.64,
            },
            itemStyle: {
              color: "#64CC5D",
              opacity: 0.64,
            },
          },
        ],
      });
      myChart.setOption(option);
    },

    //机构调研
    loadChart5_2() {
      let myChart = this.$echarts.init(
        document.getElementById("chart5_2"),
        null,
        { renderer: "svg" }
      );
      myChart.setOption({
        xAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
        },
        grid: {
          bottom: 20,
          top: 10,
          left: 5,
          right: 50,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#AEAEAE",
          },
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#AEAEAE",
            },
          },
          axisLabel: {
            fontSize: 15,
            formatter(value) {
              return value.toFixed(2);
            },
          },
        },
        series: [
          {
            data: [
              {
                value: this.fVal,
                itemStyle: {
                  color: "#FC4848",
                },
              },
              {
                value: this.fIndInsResAvg,
                itemStyle: {
                  color: "#64CC5D",
                },
              },
            ],
            type: "bar",
            barWidth: 38,
          },
        ],
      });
    },
    //主力资金
    loadChart6() {
      let myChart = this.$echarts.init(
        document.getElementById("chart6"),
        null,
        { renderer: "svg" }
      );
      myChart.setOption({
        grid: {
          left: "0",
          right: "0",
          bottom: "30",
          top: 0,
        },
        xAxis: [
          {
            type: "category",
            data: this.chart6Time.reverse(),
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#707070",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#AEAEAE",
                fontSize: "16",
              },
            },
          },
          {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#707070",
                type: "dashed",
              },
            },
            position: "bottom",
          },
        ],
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: this.chart6Data.reverse(),
            type: "bar",
            barWidth: 40,
            itemStyle: {
              normal: {
                color: (item) => {
                  if (item.value > 0) {
                    return "#FC4848";
                  } else {
                    return "#64CC5D";
                  }
                },
              },
            },
          },
        ],
      });
    },
    //主力成本
    loadChart7() {
      let myChart = this.$echarts.init(
        document.getElementById("chart7"),
        null,
        { renderer: "svg" }
      );
      myChart.setOption({
        grid: {
          bottom: 40,
          top: 10,
          left: 0,
          right: 50,
        },
        xAxis: {
          type: "category",
          data: this.sUpdateDate.reverse(),
          axisLine: {
            show: true,
            lineStyle: {
              color: "#707070",
            },
          },
          axisLabel: {
            align: "left",
            textStyle: {
              color: "#AEAEAE",
              fontSize: 16,
            },
            interval: 5,
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          min: this.min_fMainAvgCost,
          max: this.max_fMainAvgCost,
          axisLabel: {
            color: "#AEAEAE",
          },
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLine: {
            show: "true",
            lineStyle: {
              color: "#AEAEAE",
            },
          },
          axisLabel: {
            fontSize: 16,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            data: this.fMainAvgCost.reverse(),
            symbol: "none",
            type: "line",
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
    },
    //龙虎榜
    loadChart8() {
      let myChart = this.$echarts.init(
        document.getElementById("chart8"),
        null,
        { renderer: "svg" }
      );
      myChart.setOption({
        grid: {
          left: "0",
          right: "30",
          bottom: "40",
          top: 10,
        },
        xAxis: {
          type: "category",
          data: this.sDate,
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          show: true,
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: this.fNetBuy,
            type: "bar",
            barWidth: 40,
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
    },
    //市场表现
    loadChart9() {
      let myChart = this.$echarts.init(
        document.getElementById("chart9"),
        null,
        { renderer: "svg" }
      );
      // console.log(this.TradeDate);
      let option = (option = {
        xAxis: {
          type: "category",
          data: this.Chart9_date,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
            },
          },
          axisLabel: {
            fontSize: 16,
            align: "left",
            interval: 5,
            padding: [0, 0, 0, 0],
            color: "#AEAEAE",
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 10,
          left: 5,
          right: 50,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#AEAEAE",
            fontSize: 16,
            formatter(value) {
              return value * 100 + "%";
            },
          },
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#707070",
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "stockUpDowns",
            data: this.Chart9_data1,
            type: "line",
            symbol: "none",

            itemStyle: {
              color: "#64CC5D",
              opacity: 0.64,
            },
          },
          {
            name: "IndexUpdowns",
            data: this.Chart9_data2,
            type: "line",
            symbol: "none",

            itemStyle: {
              color: "#F78937",
              opacity: 0.64,
            },
          },
          {
            name: "PlateUpdowns",
            data: this.Chart9_data3,
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FC4848",
              opacity: 0.64,
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart10() {
      let myChart = this.$echarts.init(
        document.getElementById("chart10"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        xAxis: {
          type: "category",
          data: this.Chart9_date,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
            },
          },
          axisLabel: {
            fontSize: 16,
            align: "left",
            interval: 5,
            color: "#AEAEAE",
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
        },

        grid: {
          bottom: 30,
          top: 10,
          left: 5,
          right: 50,
        },
        yAxis: {
          type: "value",

          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#707070",
            },
          },
          axisLabel: {
            fontSize: 16,
            color: "#AEAEAE",
            formatter(value) {
              return value * 100 + "%";
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "IndexUpdowns",
            data: this.Chart9_data2,
            type: "line",
            symbol: "none",
            areaStyle: {
              opacity: 0.64,
            },
            itemStyle: {
              color: "#F78937",
              opacity: 0.64,
            },
          },
          {
            name: "PlateUpdowns",
            data: this.Chart9_data3,
            type: "line",
            symbol: "none",

            itemStyle: {
              color: "#FC4848",
              opacity: 0.64,
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    //市盈率
    loadChart11() {
      let myChart = this.$echarts.init(
        document.getElementById("chart11"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "50",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          boundaryGap: [0, 0.01],
          data: this.chart11_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 16,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 16,
            color: "#AEAEAE",
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart11_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart11_data2,
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart12() {
      let myChart = this.$echarts.init(
        document.getElementById("chart12"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "50",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          boundaryGap: [0, 0.01],
          data: this.chart12_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 16,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 16,
            color: "#AEAEAE",
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart12_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart12_data2,
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart13() {
      let myChart = this.$echarts.init(
        document.getElementById("chart13"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "80",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          boundaryGap: [0, 0.01],
          data: this.chart13_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
            formatter: function (value) {
              return parseInt(value / 100000000);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart13_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart13_data2.slice(5),
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart14() {
      let myChart = this.$echarts.init(
        document.getElementById("chart14"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "80",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          boundaryGap: [0, 0.01],
          data: this.chart14_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
            formatter: function (value) {
              return (value / 100000000).toFixed(2);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart14_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart14_data2.slice(5),
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      // console.log(this.chart14_data1, this.chart14_data2);
      myChart.setOption(option);
    },
    loadChart15() {
      let myChart = this.$echarts.init(
        document.getElementById("chart15"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "80",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, 0.01],
          data: this.chart15_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart15_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart15_data2,
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart16() {
      let myChart = this.$echarts.init(
        document.getElementById("chart16"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "80",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, 0.01],
          data: this.chart16_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          // boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart16_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart16_data2,
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart18() {
      let myChart = this.$echarts.init(
        document.getElementById("chart18"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "80",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, 0.01],
          data: this.chart18_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart18_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart18_data2.reverse(),
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart17() {
      let myChart = this.$echarts.init(
        document.getElementById("chart17"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "80",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, 0.01],
          data: this.chart17_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          // boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
            formatter: function (value) {
              return parseInt(value / 100000000);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart17_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart17_data2.reverse(),
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart19() {
      let myChart = this.$echarts.init(
        document.getElementById("chart19"),
        null,
        { renderer: "svg" }
      );
      let option = (option = {
        grid: {
          left: "0",
          right: "100",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, 0.01],
          data: this.chart19_date.reverse(),
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
          },
        },
        yAxis: {
          type: "value",
          // boundaryGap: [0, 0.01],
          position: "right",
          splitLine: {
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 17,
            color: "#AEAEAE",
            formatter: function (value) {
              return parseInt(value / 100000000).toFixed(2);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: this.chart19_data1.reverse(),
            itemStyle: {
              color: "#64CC5D",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: this.chart19_data2,
            itemStyle: {
              color: "#FC4848",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    endRecord() {
      let arr = [
        2,
        3 + this.audioTimeList[0],
        4 + this.audioTimeList[0] + this.audioTimeList[1],
        5 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2],
        7 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          5,
        8 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          5 +
          this.audioTimeList[4],
        9 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          5 +
          this.audioTimeList[4] +
          this.audioTimeList[5],
        10 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          5 +
          this.audioTimeList[4] +
          this.audioTimeList[5] +
          this.audioTimeList[6],
        11 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          5 +
          this.audioTimeList[4] +
          this.audioTimeList[5] +
          this.audioTimeList[6] +
          this.audioTimeList[7],
      ];
      let audiosOrderList = this.audioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: arr[index],
          audioLen: item.audio_length,
        };
      });
      console.log(audiosOrderList);
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.recordtimeAll,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },

    initKeyFrames(imgArr) {
      // this.keyFrames1 = new CanvasKeyFrames(document.querySelector('.bg1'),"array",imgArr,{fps:24,width:144,height:256,imgWidth:144,imgHeight:256})
      this.keyFrames2 = new CanvasKeyFrames(
        document.querySelector(".bg_gif"),
        "array",
        imgArr,
        { fps: 24, width: 144, height: 256, imgWidth: 144, imgHeight: 256 }
      );
      this.keyFrames2.repeatplay(0, 50);
      //this.keyFrames1.play()
    },
    dramCanvasFrames() {
      var count = 0;
      var imgArr = [];
      const _this = this;
      for (var i = 1; i <= 100; i++) {
        (function (i) {
          var img = new Image();
          img.onload = function () {
            img.onload = null;
            count++;
            // 有可能图片加载有快有满慢，所以用角标存
            imgArr[i - 1] = img;
            if (count == 100) {
              _this.imgArr = imgArr;
              console.log(imgArr);
              _this.initKeyFrames(imgArr);
            }
          };
          img.onerror = function () {};
          img.src = `img/9 (${i}).png`;
        })(i);
      }
    },

    load() {
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 500);

      setTimeout(() => {
        this.dramCanvasFrames();
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        // this.$refs.audio1.play();
        this.loadChart1();
      }, 2000); //间隔

      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (2 + this.audioTimeList[0]) * 1000);

      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        // this.$refs.audio2.play();
        this.loadChart3_1();
        this.loadChart3_2();
        this.loadChart5D3();
      }, (3 + this.audioTimeList[0]) * 1000); //间隔

      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, (3 + this.audioTimeList[0] + this.audioTimeList[1]) * 1000);

      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        // this.$refs.audio3.play();
        this.loadChart5();
        this.loadChart5_2();
      }, (4 + this.audioTimeList[0] + this.audioTimeList[1]) * 1000); //间隔

      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, (4 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2]) * 1000);

      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        // this.$refs.audio4.play();
        this.loadChart6();
        this.loadChart7();
        this.loadChart8();
      }, (5 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2]) * 1000); //间隔

      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, (5 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000);

      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.loadChart9();
        this.loadChart10();
      }, (6 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000); //间隔

      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, (6 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5) * 1000);

      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        // this.$refs.audio5.play();
        this.loadChart11();
        this.loadChart12();
      }, (7 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5) * 1000);

      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, (7 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        // this.$refs.audio6.play();
        this.loadChart13();
        this.loadChart14();
      }, (8 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card8").addClass("animate__fadeOut");
      }, (8 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5]) * 1000);

      setTimeout(() => {
        $("#card8").addClass("none");
        $("#card9").removeClass("none");
        // this.$refs.audio7.play();
        this.loadChart15();
        this.loadChart16();
      }, (9 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5]) * 1000);

      setTimeout(() => {
        $("#card9").addClass("animate__fadeOut");
      }, (9 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6]) * 1000);

      setTimeout(() => {
        $("#card9").addClass("none");
        $("#card10").removeClass("none");
        // this.$refs.audio8.play();
        this.loadChart17();
        this.loadChart18();
      }, (10 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6]) * 1000);

      setTimeout(() => {
        $("#card10").addClass("animate__fadeOut");
      }, (10 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6] + this.audioTimeList[7]) * 1000);

      setTimeout(() => {
        $("#card10").addClass("none");
        $("#card11").removeClass("none");
        // this.$refs.audio9.play();
        this.loadChart19();
      }, (11 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6] + this.audioTimeList[7]) * 1000);

      setTimeout(() => {
        $("#card11").addClass("animate__fadeOut");
      }, (11 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6] + this.audioTimeList[7] + this.audioTimeList[8]) * 1000);

      setTimeout(() => {
        $("#card11").addClass("none");
        $("#card12").removeClass("none");
         $(".bg_gif").addClass("none");
        this.$refs.endVideo.play();
      }, (12 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + 5 + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6] + this.audioTimeList[7] + this.audioTimeList[8]) * 1000);

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, (this.recordtimeAll + 2) * 1000);
      }
    },
  },
};
</script>

<style scoped lang="less">
.max_box {
  background: #070a30;
  overflow: hidden;
}
.logo-box {
  width: 377px;
}
.card {
  background: #070a30;
  color: #fff;
  font-size: 16px;
}
.sScoreDesc_w {
  width: 121px;
  margin: 0 auto;
}
.wrap {
  position: absolute;
  top: 24%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.stock-name {
  color: #f68630;
  font-size: 50px;
  margin-bottom: 83px;
}
.main-title {
  color: #fff;
  font-size: 70px;
  margin-top: 10px;
}
.head-tit {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
  margin-bottom: 50px;
}
.head-tit span {
  margin-left: 16px;
  color: #fff;
  font-size: 35px;
  font-weight: 400;
  position: relative;
}
.head-tit span::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 7px;
  width: 3px;
  height: 35px;
  background-color: #707070;
}
.tips {
  position: absolute;
  bottom: 1%;
  color: #616161;
  width: 260px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
}
.chart1 {
  width: 470px;
  height: 450px;
  margin: 96px auto;
}
.intro {
  text-align: center;
  width: 260px;
  margin: 0 auto;
}
.intro p {
  font-size: 25px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 400;
  width: 100%;
}
.intro P:nth-of-type(2) {
  color: #fb8021;
  font-size: 80px;
}
.container {
  padding: 0 5%;
  box-sizing: border-box;
}

.container h2 {
  font-size: 35px;
  color: #fff;
  margin-top: 15px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 25px;
}
.box-content {
  display: flex;
  width: 624px;
  height: 190px;
  margin-bottom: 63px;
}
.chart2 {
  width: 245px;
  height: 112px;
}
.chart3 {
  width: 80px;
  height: 112px;
}
.chart5 {
  width: 626px;
  height: 170px;
  margin: 0 auto;
  margin-bottom: 50px;
  div {
    width: 100%;
  }
}
.chart11 {
  width: 626px;
  height: 250px;
  margin: 0 auto;
  margin-bottom: 50px;
  div {
    width: 100%;
  }
}

.progess,
.line_leged {
  display: flex;
  align-items: left;
  margin: 8px 0px;
}
.line_leged .line_press {
  color: #cecece;
}
.line_press span:nth-child(2),
.line_avge span:nth-child(2),
.line_support span:nth-child(2) {
  font-size: 18px;
}

.line_leged .line_press span:first-of-type {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: #fc4848;
  margin-right: 6px;
}
.line_leged .line_avge {
  color: #cecece;
  margin: 0 9px;
}
.line_leged .line_avge span:first-of-type {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: #ff8a33;
  margin-right: 6px;
}
.line_leged .line_support {
  color: #cecece;
}
.line_leged .line_support span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #447ade;
  margin-right: 6px;
}
.product,
.product_line {
  display: flex;
  align-items: left;
  margin: 8px 0px;
}
.product_line span:first-of-type {
  display: inline-block;
  width: 23px;
  height: 4px;
  margin-right: 6px;
  vertical-align: super;
}
.line1 span:first-of-type {
  background: #64cc5d;
}
.line1 span:nth-of-type(2) {
  color: #64cc5d;
}
.line2 span:first-of-type {
  background: #fc4848;
  margin-left: 16px;
}
.line2 span:nth-of-type(2) {
  color: #fc4848;
}
.line3 span:first-of-type {
  background: #f5812b;
  margin-left: 16px;
}
.line3 span:nth-of-type(2) {
  color: #f5812b;
}
.line_leged .line_avge {
  color: #cecece;
  margin: 0 9px;
}
.line_leged .line_avge span:first-of-type {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: #ff8a33;
  margin-right: 6px;
}
.line_leged .line_support {
  color: #cecece;
}
.line_leged .line_support span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #447ade;
  margin-right: 6px;
}

#card5_chart {
  width: 100%;
  height: 360px;
}
.chart_content {
  height: 360px;
}
.d3_chip {
  padding-top: 0px;
}

.chart_content > div {
  height: 100%;
  width: 100%;
}
.Desc {
  font-size: 25px;
  color: #cecece;
  margin-bottom: 18px;
}
#chart3_1 {
  width: 375px;
  height: 182px;
}
#chart3_2 {
  width: 249px;
  height: 182px;
}

#chart9,
#chart10 {
  height: 190px;
}

.grade {
  margin: 0 auto;
  span {
    display: inline-block;
    width: 20%;
    height: 12px;
  }
  span:first-of-type {
    background: #418c30;
  }
  span:nth-of-type(2) {
    background: #5cb840;
  }
  span:nth-of-type(3) {
    background: #c2cf3c;
  }
  span:nth-of-type(4) {
    background: #f39b2b;
  }
  span:nth-of-type(5) {
    background: #fc4848;
  }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.sub_title {
  font-size: 18px;
  color: #d5d5d5;
}
#card7,
#card8,
#card9,
#card10,
#card11 .progess {
  .line_press span:first-of-type {
    background: #64cc5d;
  }
  .line_press span:nth-child(2),
  .line_avge span:nth-child(2) {
    font-size: 18px;
  }
  .line_avge span:first-of-type {
    background: #fc4848;
  }
}
#card4 .line_avge {
  span:first-of-type {
    background: #64cc5d;
  }
}
#card3 {
  font-size: 10px;
}
.bg_gif {
  width: 144px;
  height: 256px;
  position: absolute;
  left: 70%;
  /* transform: translateX(-50%); */
  top: 60px;
  transform: scale(2);
}
</style>
